@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&family=Roboto:wght@300&display=swap');

.App {
  text-align: center;
}

.header {
  background: url('./images/header-image.webp');
  background-size: cover;
  background-position: 0% 80%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header__h1, .header__h2 {
  font-family: 'Playfair Display SC', 'sans-serif';
  text-shadow: 5px 5px 3px black;
}

.header__h1 {
  font-size: calc(14px + 6vw);
}

.header__h2 {
  font-size: calc(10px + 2vw);
}

main {
  width: 100%;
  height:max-content;
  max-width: 1200px;
  margin: 1rem auto;
  position: relative;
  min-height: calc(100vh - 40vh);
}

section {
  padding: 1rem;
  padding-bottom: 2.5rem;
  overflow-x: clip;
}