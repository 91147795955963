
.card {
    position: relative;
    width: 90%;
    margin: 2rem auto ;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-rows: minmax(200px, 1fr);
}

.card--alternate {
    position: relative;
    width: 90%;
    margin: 2rem auto ;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-rows: minmax(200px, 1fr);
} 

/*  card with alternates */
.card__img-wrapper {
    grid-column: 1/ 2;
    grid-row: 1/2;
    aspect-ratio:1/1 ;
}

.card__img-wrapper--alternate {
    grid-column: 2 / 3;
    grid-row: 1/2;
    aspect-ratio: 1/1;
}

.card__info-container {
    grid-column: 2 / 3;
    grid-row: 1/2;
}

.card__info-container--alternate {
    grid-column: 1 / 2;
    grid-row: 1/2;
}

.card__info-container, .card__info-container--alternate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 3rem;
}

/* card__img */
.card__img-container, .card__img-container--alternate {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    background-color: whitesmoke;
}

.card__img-container::before, 
.card__img-container::after, 
.card__img-container--alternate::before, 
.card__img-container--alternate::after {
    content: "";
    height: 4vh;
    width: 9vh;
    position: absolute;
}

.card__img-container::before{
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.3);
    transform: translate(-2.5vh, 0.5vh) rotate(-45deg) scaleX(1.5) ;
}

.card__img-container::after {
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .3);
    transform: translate(2.5vh, -0.5vh) rotate(-45deg) scaleX(1.5) ;
}

.card__img-container__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* card-img alternate */
.card__img-container--alternate::before{
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .3);
    transform: translate(-2.5vh, 0.5vh) rotate(45deg) scaleX(1.5) ;
}

.card__img-container--alternate::after {
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .3);
    transform: translate(2.5vh, -0.5vh) rotate(45deg) scaleX(1.5) ;
}

@media (max-width: 700px) {

    .card__img-wrapper, .card__img-wrapper--alternate {
        grid-column: 1/ 2;
        grid-row: 1/2
    }
    .card__info-container,  .card__info-container--alternate {
        grid-column: 1/ 2;
        grid-row: 2/3
    }

    .card__info-container, .card__info-container--alternate {
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding: 1rem 3rem 3rem 3rem;
    }


}



