
.person-card {
    position: relative;
    width: 90%;
    margin: 2rem auto ;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-rows: minmax(200px, 1fr);
}

/*  card images */
.person-card__image-wrapper {
    grid-column: 1/ 2;
    grid-row: 1/2;
    aspect-ratio:1/1 ;
    overflow: hidden;
    display: flex;
    align-self: center;
}

.person-card__image-mask {
    -webkit-mask-image: url(./images/black-ink.png);
    mask-image: url(./images/black-ink.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-size: 110% 160%;
    mask-position: center;
    border-radius: 10px;
    overflow: hidden;
}

.person-card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

/* card info*/
.person-card__info {
    grid-column: 2 / 3;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 3rem;
    border: 3px solid black;
    border-radius: 5px;
}

.person-card__info > div{
    height: 3px;
    width: calc(100% + 6rem);
    background: black;
}

.person-card__info-phrase {
    font-style: italic;
    font-weight: 500;
    font-size: 1rem;
}

.person-card__ul {
    padding-inline-start: 0px;
}

.person-card__ul > li{
    list-style-type: none;
    list-style: none;
    text-align: justify;
    margin-bottom: 0.5rem;
}

.person-card__span {
    font-weight: 900;
}

@media (max-width: 700px) {

    .person-card {
        margin: 0;
        width: 100%;
        margin-bottom: 3.5rem;
    }

    .person-card__image-wrapper {
        grid-column: 1/ 2;
        grid-row: 1/2
    }

    
    .person-card__info {
        grid-column: 1/ 2;
        grid-row: 2/3;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding: 1rem 3rem 3rem 3rem;
        margin: 1rem;
    }

    section {
        padding: 0;
    }

}

@media (max-width: 1200px ) {

    .person-card__info {
        font-size: 0.75rem;
        padding: 1rem
    }

    .person-card__info > div{
        width: calc(100% + 2rem);
    }

}