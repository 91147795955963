.link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 7rem auto;
    gap: 1.5rem;
}

footer {
    font-weight: 500;
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
}
