.nav-wrapper {
    margin-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9999;
}

#nav--sticky {
    display: block;
    width: 100%;
    border: 2px solid black;
    background: darkgoldenrod;
}

nav > ul {
    padding-inline-start: 0;
}


#nav__list {
    display:flex;
    width: 100%;
    list-style: none;
    justify-content: center;
    align-items: center;
}

#nav__list  li {
    border-left: 2px solid black;
    padding: 0 1.5rem;
}

.nav__list-item {
    font-weight: 600;
    font-size: calc(10px + 1vw);
}

#nav__list > li:first-child {
    border-left: none;
}

#nav_mini {
    position: fixed;
    z-index: 1000;
    width: 90%;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0%);
}

.nav_mini-background {
    z-index: -1;
    border: 2px solid black;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba(184, 135, 11, 0.589);
    border-radius: 5px;
  }

#nav__list-mini {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
}

#nav__list-mini > li {
    padding: 1rem;
    width: 100%;
    border-bottom: 2px solid black;
}

#nav__list-mini > li:hover {
    width: calc(100% * 1.1);
}


#nav__list-mini > li:hover {
    padding: 1rem;
    width: calc(100% * 1.1);
    border-bottom: 2px solid black;
}

#nav__list-mini > li:last-child {
    border-bottom: none;
    margin-bottom: 2rem;
}

#nav__button {
    position: fixed;
    border-radius: 50%;
    z-index: 3000;
    bottom: 2rem;
    right: 2rem;
    background: transparent;
    outline: none;
    border: none;
}



#nav__list-mini > a {
    padding: 1rem auto;
}


.active .nav__list-item{
    color: black;
    background: linear-gradient(90deg, rgba(184,134,11,1) 0%, rgba(228,199,174,1) 20%, rgba(228,199,174,1) 80%, rgba(184,134,11,1) 100%);
    padding: 0 1.5rem;
}

.active {
    color: black;
    font-weight: 900;
}

